import routerProvider from "@pankod/refine-react-router-v6"
import { Container, Box } from "@mui/material"

const { Link } = routerProvider

export const Layout: React.FC = ({ children }) => {
    return (
        <>
            <Container maxWidth="sm" sx={{ padding: 2, textAlign: "center" }}>
                <Link to="/">
                    <Box
                        component="img"
                        src="/njs_ask_logo.png"
                        sx={{
                            height: 36,
                            width: "auto",
                        }}
                    ></Box>
                </Link>
            </Container>
            <Box>{children}</Box>
        </>
    )
}

import React, { useEffect, useState } from "react"

import {
    Card,
    Typography,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Badge,
    Chip,
    Avatar,
} from "@mui/material"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import { IReply, IReplyLike, IUser } from "../interfaces"
import { ReplyEditDialog } from "./ReplyEditDialog"
// import { ReplyDeleteDialog } from './ReplyDeleteDialog'

import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import { getCookie, setCookie } from "utils/cookies"
import { useCreate, useDelete, useList, useUpdate } from "@pankod/refine-core"

interface ReplyCardProps {
    reply: IReply
    replies: IReply[]
    setReplies: (value: React.SetStateAction<IReply[]>) => void
}

export const ReplyCard: React.FC<ReplyCardProps> = ({
    reply,
    replies,
    setReplies,
}) => {
    const [replyID, setReplyID] = useState<string>("")
    const [replyLikeCount, setReplyLikeCount] = useState<number>(0)

    useEffect(() => {
        if (reply) {
            setReplyID(reply.id)
            setReplyLikeCount(reply.likeCount)
        }
    }, [reply])

    const userCookie = getCookie("userID")
    const [userID, setUserID] = useState<string | null>(userCookie || null)

    const userReplyLikes = useList<IReplyLike>({
        resource: "replyLikes",
        config: {
            filters: [
                {
                    field: "replyID",
                    operator: "eq",
                    value: replyID,
                },
                {
                    field: "userID",
                    operator: "eq",
                    value: userID,
                },
            ],
        },
    })
    const [isReplyLiked, setIsReplyLiked] = useState<boolean>(false)

    useEffect(() => {
        setIsReplyLiked(!!userReplyLikes.data?.total)
    }, [userReplyLikes])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const replyMenuOpen = Boolean(anchorEl)

    const [replyEditOpen, setReplyEditOpen] = React.useState(false)
    // const [replyDeleteOpen, setReplyDeleteOpen] = React.useState(false)

    const handleReplyMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleReplyMenuClose = () => {
        setAnchorEl(null)
    }

    const handleReplyEditClick = () => {
        setReplyEditOpen(true)
    }

    const handleReplyEditClose = () => {
        setReplyEditOpen(false)
    }

    // const handleReplyDeleteClick = () => {
    //     setReplyDeleteOpen(true)
    // }

    // const handleReplyDeleteClose = () => {
    //     setReplyDeleteOpen(false)
    // }

    const { mutate: replyLikeCreate } = useCreate<IReplyLike>()
    const { mutate: replyLikeDelete } = useDelete<IReplyLike>()
    const { mutate: replyUpdate } = useUpdate<IReply>()
    const { mutate: userCreate } = useCreate<IUser>()

    const handleReplyLike = () => {
        if (!userID) {
            userCreate(
                {
                    resource: "users",
                    values: {},
                },
                {
                    onSuccess: ({ data }) => {
                        replyLikeCreate({
                            resource: "replyLikes",
                            values: {
                                userID: data.id,
                                replyID,
                            },
                        })
                        setCookie("userID", data.id)
                        setUserID(data.id)
                    },
                    onError: (error) => {
                        console.log(error)
                    },
                }
            )
        } else {
            replyLikeCreate({
                resource: "replyLikes",
                values: {
                    userID,
                    replyID,
                },
            })
        }

        replyUpdate(
            {
                resource: "replies",
                id: replyID,
                values: {
                    likeCount: replyLikeCount + 1,
                },
            },
            {
                onSuccess: (data) => {
                    setReplyLikeCount(data.data.likeCount)
                    setIsReplyLiked(true)
                },
                onError: (error) => {
                    console.log(error)
                },
            }
        )
    }

    const handleReplyUnlike = () => {
        replyLikeDelete(
            {
                resource: "replyLikes",
                id: userReplyLikes.data?.data[0].id || "",
            },
            {
                onSuccess: () => {
                    replyUpdate(
                        {
                            resource: "posts",
                            id: replyID,
                            values: {
                                likeCount: replyLikeCount - 1,
                            },
                        },
                        {
                            onError: (error) => {
                                console.log(error)
                            },
                        }
                    )

                    setReplyLikeCount(replyLikeCount - 1)
                    setIsReplyLiked(false)
                },
            }
        )
    }

    return (
        <Card sx={{ padding: 2, marginBottom: 2 }}>
            <Box
                sx={{
                    position: "relative",
                    float: "right",
                    zIndex: 100,
                }}
            >
                <IconButton
                    color="primary"
                    aria-label="edit"
                    aria-controls={replyMenuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={replyMenuOpen ? "true" : undefined}
                    onClick={handleReplyMenuClick}
                    sx={{ p: 0 }}
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={replyMenuOpen}
                    onClose={handleReplyMenuClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem onClick={handleReplyEditClick}>수정하기</MenuItem>
                    <ReplyEditDialog
                        reply={reply}
                        open={replyEditOpen}
                        onClose={handleReplyEditClose}
                    />

                    {/* <MenuItem onClick={handleReplyDeleteClick}>삭제하기</MenuItem>
                    <ReplyDeleteDialog
                        replyID={reply.id}
                        password={reply.password}
                        open={replyDeleteOpen}
                        onClose={handleReplyDeleteClose}
                        replies={replies}
                        setReplies={setReplies}
                    /> */}
                </Menu>
            </Box>

            <Box sx={{ width: "100%" }}>
                <Typography gutterBottom sx={{ wordBreak: "break-all" }}>
                    {reply.content}
                </Typography>
            </Box>

            <Box sx={{ display: "block" }}>
                <Typography
                    sx={{ display: "inline-block", textAlign: "left" }}
                    gutterBottom
                >
                    {new Date(reply.createdAt).toLocaleString()}
                </Typography>
                <Box
                    sx={{
                        float: "right",
                    }}
                >
                    <Badge badgeContent={replyLikeCount} color="primary">
                        {isReplyLiked ? (
                            <FavoriteIcon
                                color="error"
                                onClick={handleReplyUnlike}
                            />
                        ) : (
                            <FavoriteBorderIcon
                                color="action"
                                onClick={handleReplyLike}
                            />
                        )}
                    </Badge>
                </Box>
            </Box>
            <Box sx={{ display: "block" }}>
                {reply.userType === "Admin" ? (
                    <Box
                        sx={{
                            float: "right",
                        }}
                    >
                        <Chip
                            avatar={
                                <Avatar alt="logo" src="/Symbol_Primary.png" />
                            }
                            label="관리자"
                            color="error"
                            variant="outlined"
                        />
                    </Box>
                ) : (
                    <Typography sx={{ textAlign: "right" }} gutterBottom>
                        {reply.user}
                    </Typography>
                )}
            </Box>
        </Card>
    )
}

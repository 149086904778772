import React, { useState } from "react"
import { useCreate, useNavigation } from "@pankod/refine-core"
import { Card, Container, Box, TextField, Snackbar, Alert } from "@mui/material"

import Fab from "@mui/material/Fab"

import AddIcon from "@mui/icons-material/Add"

import { IPost } from "../../interfaces"

export const PostCreate: React.FC = () => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [user, setUser] = useState("")

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")

    const { show } = useNavigation()

    const { mutate } = useCreate<IPost>()

    const handleSubmit = () => {
        if (!title) {
            setSnackbarMessage("제목을 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        if (!user) {
            setSnackbarMessage("작성자를 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        if (!content) {
            setSnackbarMessage("내용을 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        mutate(
            {
                resource: "posts",
                values: {
                    title,
                    content,
                    user,
                    postType: "의견등록",
                    userType: "Student",
                    likeCount: 0,
                },
            },
            {
                onSuccess: ({ data }) => {
                    setTitle("")
                    setContent("")
                    setUser("")
                    show("posts", data.id)
                },
                onError: (error) => {
                    console.log(error)
                },
            }
        )
    }

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleContent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value)
    }

    const handleUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser(e.target.value)
    }

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return
        }

        setSnackbarOpen(false)
    }

    return (
        <Container maxWidth="sm">
            <Card sx={{ padding: 2, marginBottom: 2 }}>
                <TextField
                    id="post-title"
                    label="제목"
                    variant="outlined"
                    sx={{
                        fontSize: "1.2rem",
                        fontWeight: 600,
                        width: "100%",
                        marginBottom: 2,
                    }}
                    value={title}
                    onChange={handleTitle}
                />

                <TextField
                    id="post-user"
                    label="작성자"
                    variant="outlined"
                    sx={{ width: "100%", marginBottom: 2 }}
                    value={user}
                    onChange={handleUser}
                />

                <TextField
                    id="post-content"
                    label="내용"
                    multiline
                    rows={6}
                    value={content}
                    sx={{ width: "100%", marginBottom: 2 }}
                    onChange={handleContent}
                />

                <Box
                    sx={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        zIndex: 100,
                    }}
                >
                    <Fab
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        onClick={handleSubmit}
                    >
                        <AddIcon sx={{ mr: 1 }} />
                        글쓰기
                    </Fab>
                </Box>
            </Card>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                sx={{ bottom: { xs: 90, sm: 0 } }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    )
}

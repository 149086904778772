import React, { useState, useEffect } from "react"
import dayjs from "dayjs"

import {
    useShow,
    useCreate,
    useUpdate,
    useList,
    useDelete,
} from "@pankod/refine-core"
import {
    Card,
    Container,
    Typography,
    TextField,
    Box,
    Fab,
    Badge,
    Snackbar,
    Alert,
    Chip,
    Avatar,
} from "@mui/material"

import AddIcon from "@mui/icons-material/Add"

import { IPost, IReply, IUser, IPostLike } from "../../interfaces"
import { ReplyCard } from "components/ReplyCard"

import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import { getCookie, setCookie } from "utils/cookies"

export const PostShow: React.FC = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")

    const { queryResult } = useShow<IPost>({
        liveMode: "auto",
    })
    const { data } = queryResult
    const record = data?.data

    const [postID, setPostID] = useState<string>("")
    const [postLikeCount, setPostLikeCount] = useState<number>(0)
    const [postDueDate, setPostDueDate] = useState<Date | null>(null)

    useEffect(() => {
        if (record) {
            setPostID(record.id)
            setPostLikeCount(record.likeCount)
            setPostDueDate(record.dueDate)
        }
    }, [record])

    const userCookie = getCookie("userID")
    const [userID, setUserID] = useState<string | null>(userCookie || null)

    const userPostLikes = useList<IPostLike>({
        resource: "postLikes",
        config: {
            filters: [
                {
                    field: "postID",
                    operator: "eq",
                    value: postID,
                },
                {
                    field: "userID",
                    operator: "eq",
                    value: userID,
                },
            ],
        },
    })
    const [isPostLiked, setIsPostLiked] = useState<boolean>(false)

    useEffect(() => {
        setIsPostLiked(!!userPostLikes.data?.total)
    }, [userPostLikes])

    const [reply, setReply] = useState<string>("")
    const [replyUser, setReplyUser] = useState<string>("")
    const [replyPassword, setReplyPassword] = useState<string>("")

    const [replies, setReplies] = useState<IReply[]>([])

    const repliesQueryResult = useList<IReply>({
        resource: "replies",
        config: {
            filters: [
                {
                    field: "postID",
                    operator: "eq",
                    value: postID,
                },
            ],
        },
    })

    useEffect(() => {
        if (repliesQueryResult.data?.data) {
            const orderedReplies = repliesQueryResult.data?.data.sort(
                (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
            )
            setReplies(orderedReplies)
        }
    }, [repliesQueryResult.data])

    const { mutate: postLikeCreate } = useCreate<IPostLike>()
    const { mutate: postLikeDelete } = useDelete<IPostLike>()
    const { mutate: replyCreate } = useCreate<IReply>()
    const { mutate: postUpdate } = useUpdate<IPost>()
    const { mutate: userCreate } = useCreate<IUser>()

    const handlePostLike = () => {
        if (!userID) {
            userCreate(
                {
                    resource: "users",
                    values: {},
                },
                {
                    onSuccess: ({ data }) => {
                        postLikeCreate({
                            resource: "postLikes",
                            values: {
                                userID: data.id,
                                postID,
                            },
                        })
                        setCookie("userID", data.id)
                        setUserID(data.id)
                    },
                    onError: (error) => {
                        console.log(error)
                    },
                }
            )
        } else {
            postLikeCreate({
                resource: "postLikes",
                values: {
                    userID,
                    postID,
                },
            })
        }

        const dueDate = postDueDate
            ? postDueDate
            : dayjs().add(20, "day").format("MM/DD/YYYY")

        postUpdate(
            {
                resource: "posts",
                id: postID,
                values: {
                    likeCount: postLikeCount + 1,
                    postType: "동의진행중",
                    dueDate,
                },
            },
            {
                onSuccess: (data) => {
                    setPostLikeCount(data.data.likeCount)
                    setIsPostLiked(true)
                },
                onError: (error) => {
                    console.log(error)
                },
            }
        )
    }

    const handlePostUnlike = () => {
        postLikeDelete(
            {
                resource: "postLikes",
                id: userPostLikes.data?.data[0].id || "",
            },
            {
                onSuccess: () => {
                    const likeCount = postLikeCount > 0 ? postLikeCount - 1 : 0

                    postUpdate(
                        {
                            resource: "posts",
                            id: postID,
                            values: {
                                likeCount,
                            },
                        },
                        {
                            onError: (error) => {
                                console.log(error)
                            },
                        }
                    )

                    setPostLikeCount(likeCount)
                    setIsPostLiked(false)
                },
            }
        )
    }

    const handleReplyUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReplyUser(e.target.value)
    }

    const handleReply = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReply(e.target.value)
    }

    const handleReplyPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReplyPassword(e.target.value)
    }

    const handleReplyCreate = () => {
        if (!record) {
            return
        }

        if (!replyUser) {
            setSnackbarMessage("작성자를 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        if (!reply) {
            setSnackbarMessage("내용을 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        if (!replyPassword) {
            setSnackbarMessage("비밀번호를 입력해주세요.")
            setSnackbarOpen(true)
            return
        }

        replyCreate(
            {
                resource: "replies",
                values: {
                    postID,
                    content: reply,
                    user: replyUser,
                    password: replyPassword,
                    likeCount: 0,
                    userType: "Student",
                },
            },
            {
                onSuccess: () => {
                    setReply("")
                    setReplyUser("")
                    setReplyPassword("")
                },
                onError: (error) => {
                    console.log(error)
                },
            }
        )
    }

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return
        }

        setSnackbarOpen(false)
    }

    return (
        <Container maxWidth="sm">
            <Card sx={{ padding: 2, marginBottom: 2 }}>
                <Typography
                    sx={{
                        fontSize: "1.2rem",
                        fontWeight: 600,
                    }}
                    gutterBottom
                >
                    {record?.title}
                </Typography>

                <Typography
                    gutterBottom
                    sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                    {record?.content}
                </Typography>
                <Box sx={{ display: "block" }}>
                    <Typography
                        sx={{ display: "inline-block", textAlign: "left" }}
                        gutterBottom
                    >
                        {record && new Date(record?.createdAt).toLocaleString()}
                    </Typography>
                    <Box
                        sx={{
                            float: "right",
                        }}
                    >
                        <Badge badgeContent={postLikeCount} color="primary">
                            {isPostLiked ? (
                                <FavoriteIcon
                                    color="error"
                                    onClick={handlePostUnlike}
                                />
                            ) : (
                                <FavoriteBorderIcon
                                    color="action"
                                    onClick={handlePostLike}
                                />
                            )}
                        </Badge>
                    </Box>
                </Box>
                <Box sx={{ display: "block" }}>
                    {record?.userType === "Admin" ? (
                        <Box
                            sx={{
                                float: "right",
                            }}
                        >
                            <Chip
                                avatar={
                                    <Avatar
                                        alt="logo"
                                        src="/Symbol_Primary.png"
                                    />
                                }
                                label="관리자"
                                color="error"
                                variant="outlined"
                            />
                        </Box>
                    ) : (
                        <Typography sx={{ textAlign: "right" }} gutterBottom>
                            {record?.user}
                        </Typography>
                    )}
                </Box>
            </Card>

            <Card sx={{ padding: 2, marginBottom: 2 }}>
                <TextField
                    id="reply-user"
                    label="작성자"
                    variant="outlined"
                    sx={{ width: "100%", marginBottom: 2 }}
                    value={replyUser}
                    onChange={handleReplyUser}
                />

                <TextField
                    id="reply-content"
                    label="내용"
                    multiline
                    rows={3}
                    value={reply}
                    sx={{ width: "100%", marginBottom: 2 }}
                    onChange={handleReply}
                />

                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            id="reply-password"
                            label="비밀번호"
                            type="password"
                            variant="outlined"
                            sx={{ width: "100%", marginBottom: 2 }}
                            value={replyPassword}
                            onChange={handleReplyPassword}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexBasis: "60%",
                            textAlign: "right",
                        }}
                    >
                        <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            onClick={handleReplyCreate}
                        >
                            <AddIcon sx={{ mr: 1 }} />
                            댓글쓰기
                        </Fab>
                    </Box>
                </Box>
            </Card>
            {replies.map((reply) => {
                return (
                    <ReplyCard
                        key={reply.replyID}
                        reply={reply}
                        replies={replies}
                        setReplies={setReplies}
                    />
                )
            })}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                sx={{ bottom: { xs: 90, sm: 0 } }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    )
}

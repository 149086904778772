import React, { useState } from 'react'

import { useUpdate } from '@pankod/refine-core'
import { Card, TextField, Box, Fab, Dialog, DialogTitle, Snackbar, Alert } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { IReply } from '../interfaces'

interface ReplyEditDialogProps {
    open: boolean
    reply: IReply
    onClose: () => void
}

export const ReplyEditDialog: React.FC<ReplyEditDialogProps> = ({ onClose, reply, open }) => {
    const [content, setContent] = useState<string>(reply?.content)
    const [user, setUser] = useState<string>(reply?.user)
    const [passwordInput, setPasswordInput] = useState<string>('')

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)

    const { mutate } = useUpdate<IReply>()

    const handleClose = () => {
        onClose()
    }

    const handleUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser(e.target.value)
    }

    const handleContent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value)
    }

    const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordInput(e.target.value)
    }

    const handleReplyEditClick = () => {
        if (reply.password !== passwordInput) {
            setSnackbarOpen(true)
            setPasswordInput('')

            return
        }

        mutate(
            {
                resource: 'replies',
                id: reply.id,
                values: {
                    content,
                    user,
                },
            },
            {
                onSuccess: () => {
                    setContent('')
                    setUser('')
                    setPasswordInput('')
                    onClose()
                },
                onError: (error) => {
                    console.log(error)
                },
            },
        )
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false)
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>댓글 수정하기</DialogTitle>
            <Card sx={{ padding: 2 }}>
                <TextField
                    id="reply-user"
                    label="작성자"
                    variant="outlined"
                    sx={{ width: '100%', marginBottom: 2 }}
                    value={user}
                    onChange={handleUser}
                />

                <TextField
                    id="reply-content"
                    label="내용"
                    multiline
                    rows={3}
                    value={content}
                    sx={{ width: '100%', marginBottom: 2 }}
                    onChange={handleContent}
                />

                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            id="reply-password"
                            label="비밀번호"
                            type="password"
                            variant="outlined"
                            sx={{ width: '100%', marginBottom: 2 }}
                            value={passwordInput}
                            onChange={handlePasswordInput}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexBasis: '60%',
                            textAlign: 'right',
                        }}
                    >
                        <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            onClick={handleReplyEditClick}
                        >
                            <AddIcon sx={{ mr: 1 }} />
                            댓글수정
                        </Fab>
                    </Box>
                </Box>
            </Card>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="error">비밀번호가 틀렸습니다.</Alert>
            </Snackbar>
        </Dialog>
    )
}

import React from "react"
import { useNavigation, useList } from "@pankod/refine-core"
import {
    Container,
    Stack,
    Paper,
    Box,
    Typography,
    Chip,
    Badge,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Fab from "@mui/material/Fab"

import AddIcon from "@mui/icons-material/Add"
import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"

import { IPost, IPostLike } from "interfaces"
import { getCookie } from "utils/cookies"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}))

export const PostList: React.FC = () => {
    const userID = getCookie("userID")

    const { data: userPostLikes } = useList<IPostLike>({
        resource: "postLikes",
        config: {
            filters: [
                {
                    field: "userID",
                    operator: "eq",
                    value: userID,
                },
            ],
        },
    })

    const { data } = useList<IPost>({
        resource: "posts",
        config: {
            sort: [{ order: "desc", field: "createdAt" }],
            pagination: { current: 1, pageSize: 1000 },
        },
        liveMode: "auto",
    })

    const noticePost = useList<IPost>({
        resource: "posts",
        config: {
            filters: [
                {
                    field: "postType",
                    operator: "eq",
                    value: "공지사항",
                },
            ],
        },
    })

    const { show, create } = useNavigation()

    const handleCreate = () => {
        create("posts")
    }

    return (
        <Container maxWidth="sm">
            <Stack spacing={3}>
                {noticePost?.data && (
                    <Item sx={{ border: 1 }}>
                        <Box>
                            <Chip
                                label="공지사항"
                                color="info"
                                sx={{ marginBottom: 1 }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    marginBottom: 1,
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {noticePost.data.data[0].content}
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ float: "left", marginLeft: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        paddingTop: 0.4,
                                    }}
                                >
                                    {new Date(
                                        noticePost.data.data[0].createdAt
                                    ).toLocaleString()}
                                </Typography>
                            </Box>
                            {/* <Box
                                sx={{
                                    float: "right",
                                    marginRight: 2,
                                }}
                            >
                                <Badge
                                    badgeContent={
                                        noticePost.data.data[0].likeCount
                                    }
                                    color="primary"
                                >
                                    {userPostLikes?.data.find(
                                        (userPostLike) =>
                                            userPostLike.postID ===
                                            noticePost.data.data[0].id
                                    ) ? (
                                        <FavoriteIcon color="error" />
                                    ) : (
                                        <FavoriteBorderIcon color="action" />
                                    )}
                                    </Badge>
                            </Box>*/}
                        </Box>
                    </Item>
                )}

                {data?.data &&
                    data?.data.map((row: any) => {
                        if (!row.postType) {
                            return null
                        }

                        if (row.postType === "미공개") {
                            return null
                        }

                        if (row.postType === "공지사항") {
                            return null
                        }

                        return (
                            <Item
                                key={row.id}
                                onClick={() => {
                                    show("posts", row.id)
                                }}
                            >
                                <Box>
                                    <Chip
                                        label={row.postType}
                                        color={
                                            row.postType === "답변완료"
                                                ? "success"
                                                : "default"
                                        }
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: "600",
                                            marginBottom: 1,
                                            wordBreak: "break-word",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {row.title}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box sx={{ float: "left", marginLeft: 2 }}>
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                                paddingTop: 0.4,
                                            }}
                                        >
                                            {new Date(
                                                row.createdAt
                                            ).toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            float: "right",
                                            marginRight: 2,
                                        }}
                                    >
                                        <Badge
                                            badgeContent={row.likeCount}
                                            color="primary"
                                        >
                                            {userPostLikes?.data.find(
                                                (userPostLike) =>
                                                    userPostLike.postID ===
                                                    row.id
                                            ) ? (
                                                <FavoriteIcon color="error" />
                                            ) : (
                                                <FavoriteBorderIcon color="action" />
                                            )}
                                        </Badge>
                                    </Box>
                                </Box>
                            </Item>
                        )
                    })}
            </Stack>
            <Box sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 100 }}>
                <Fab
                    color="primary"
                    aria-label="add"
                    variant="extended"
                    onClick={handleCreate}
                >
                    <AddIcon sx={{ mr: 1 }} />
                    글쓰기
                </Fab>
            </Box>
        </Container>
    )
}

import { Refine } from "@pankod/refine-core"
import routerProvider from "@pankod/refine-react-router-v6"
import dataProvider from "@pankod/refine-airtable"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import { Layout } from "./components/Layout"
import { PostList, PostShow, PostCreate } from "./pages/posts"

const theme = createTheme({
    typography: {
        fontFamily: [
            "Pretendard",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    paddingTop: "2px",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#22489d",
        },
    },
})

function App() {
    const API_TOKEN = process.env.REACT_APP_AIRTABLE_API_TOKEN || ""
    const BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID || ""
    const TABLE_ID = process.env.REACT_APP_AIRTABLE_TABLE_ID || ""

    return (
        <ThemeProvider theme={theme}>
            <Refine
                routerProvider={routerProvider}
                dataProvider={dataProvider(API_TOKEN, BASE_ID)}
                resources={[
                    {
                        name: TABLE_ID,
                        list: PostList,
                        show: PostShow,
                        create: PostCreate,
                        options: { label: "게시글", route: "posts" },
                    },
                ]}
                Layout={Layout}
            />
        </ThemeProvider>
    )
}

export default App
